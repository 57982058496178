import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Script from "next/script";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  ComponentWithoutSidebar,
  ComponentWithSidebar,
  getPropsMainNav,
} from "../components/ComponentWithSidebar";
import { isProduction } from "../components/tools";
import { AlertContextProvider } from "../contexts/AlertContext/parent";
import { AuthProvider, useGetAuth } from "../contexts/AuthContext";
import { ConstantsProvider } from "../contexts/ConstantsContext/parent";
import { GoogleDrivePreviewProvider } from "../contexts/GoogleDrivePreviewContext/parent";
import { InvoiceProvider } from "../contexts/InvoiceContext";
import { TimerProvider } from "../contexts/TimerContext";
import { TncContextProvider } from "../contexts/TncContext";
import { ToasterProvider } from "../contexts/ToasterContext";
import ErrorBoundary from "../errorBoundaries";
import RestrictedPage from "../pageComponents/restrictedPageComponents";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";
import rebranding from "../assets/images/rebranding.svg";
import { GothamMedium, GothamRegular } from "../components/Text";
import { useRouter } from "next/router";
import Head from "next/head";

const App = ({ Component, pageProps }) => {
  const { hideSideBarAndNavbar, hideSideBar } = getPropsMainNav();
  const { user } = useGetAuth();
  const isBlocked = user?.status == "blocked";

  if (isBlocked) return <RestrictedPage />;

  if (hideSideBarAndNavbar) return <Component {...pageProps} />;

  if (hideSideBar)
    return (
      <ComponentWithoutSidebar Component={Component} pageProps={pageProps} />
    );

  return <ComponentWithSidebar Component={Component} pageProps={pageProps} />;
};

const MyApp = ({ Component, pageProps }) => {
  useEffect(initEvents, []);
  const __html = `(function(i, s, o, g, r, a, m, n) {
    i.moengage_object = r;
    t = {};
    q = function(f) {
      return function() {
        (i.moengage_q = i.moengage_q || []).push({ f: f, a: arguments });
      };
    };
    (f = [
      "track_event",
      "Web_Start_your_application",
      "Web_Signup",
      "Web_Want_BRE1_limitincrease_click",
      "Web_Happy_with_BRE1limit_click",
      "Web_Complete_Profile",
      "Web_On_bank_statement",
      "Web_Bank statement click_perfios",
      "Web_Bank statement click_manual",
      "Web_Suspend_bank_statement_perfios",
      "Web_Suspend_bank_statement_manual",
      "Web_Suspend_salaryslip",
      "Web_Smart_Repay_Click",
      "Web_downloadapp",
      "destroy_session",
      "add_unique_user_id",
      "moe_events",
      "call_web_push",
      "track",
      "location_type_attribute"
    ]),
      (h = { onsite: ["getData"] });
    for (k in f) {
      t[f[k]] = q(f[k]);
    }
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
    i.moe =
      i.moe ||
      function() {
        n = arguments[0];
        return t;
      };
    a.onload = function() {
      if (n) {
        i[r] = moe(n);
      }
    };
  })(
    window,
    document,
    "script",
    "https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js",
    "Moengage"
  );
  Moengage = moe({
    app_id: "N0KD2SFSC8T11S7CTD3VUJHV",
    debug_logs: 0
  });`;

  const googleAnalyticsId = "G-9XQ9B6H2ZS";
  const googleAnalyticsId2 = "UA-152684683-1";

  const GoogleAnalytics = ({ id }) => (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      />
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', ${id}', {
      page_path: window.location.pathname,
      });
  `}
      />
    </>
  );

  const { push } = useRouter();

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Head>
        <title>Transfez Business</title>
        <link rel="icon" href="/new-favicon.png" />
      </Head>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          flexDirection: "column",
          gap: 32,
        }}
      >
        <img src={rebranding} />
        <div>
          <GothamMedium
            style={{ textAlign: "center", color: "white", marginBottom: 16 }}
            className="font28"
          >
            Transfez Business is now Jack
          </GothamMedium>
          <GothamRegular
            style={{ textAlign: "center", color: "white", width: 852 }}
          >
            Exciting news, Transfez Business users! Transfez Business is
            rebranding to Jack and we have created the new Jack portal. Access
            the Jack portal using your existing Transfez Business Account.
            Rest-assured, no actions needed from your side. Your account details
            and transaction history have been transferred securely. Let's embark
            this new journey together and unlock many enhanced features and
            services on Jack. Log in now and take your finance on autopilot with
            Jack
          </GothamRegular>
        </div>
        <div
          style={{
            backgroundColor: "#B9FC00",
            width: 200,
            height: 40,
            borderRadius: 4,
          }}
          className="d-flex justify-content-center align-items-center hover"
          onClick={() => push("https://business.itsjack.com/login/")}
        >
          <GothamMedium>Log in to Jack</GothamMedium>
        </div>
      </div>
    </div>
  );

  return (
    <ToasterProvider>
      <AuthProvider>
        <InvoiceProvider>
          <TimerProvider>
            <ConstantsProvider>
              <GoogleDrivePreviewProvider>
                <AlertContextProvider>
                  <TncContextProvider>
                    <GoogleAnalytics id={googleAnalyticsId} />
                    <GoogleAnalytics id={googleAnalyticsId2} />
                    {isProduction && (
                      <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                          __html,
                        }}
                      />
                    )}
                    <ErrorBoundary>
                      <App Component={Component} pageProps={pageProps} />
                    </ErrorBoundary>
                  </TncContextProvider>
                </AlertContextProvider>
              </GoogleDrivePreviewProvider>
            </ConstantsProvider>
          </TimerProvider>
        </InvoiceProvider>
      </AuthProvider>
    </ToasterProvider>
  );
};

export default MyApp;
